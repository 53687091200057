<template lang="pug">
iframe(:src='getIframeUrl' class='w-full h-[900px]')
</template>

<script setup lang="ts">
const rootStore = useRootStore()
const props = defineProps<{
  params: any
}>()
const getIframeUrl = computed(() => {
  // https://portal.coverking.com/
  // DEV URL
  // return (
  //   '//iframe.coverking.com/' +
  //   '?dl=rti58B&SessionID=iwjkacjqwxf1tzfmj4gg41552013628102525&prod=' +
  //   props.params?.prod +
  //   '&mCat=' +
  //   props.params?.mcat +
  //   '&fullwidth=yes' +
  //   '&mobile=yes'
  // )

  return (
    '//iframe.coverking.com/' +
    '?dl=rti59&SessionID=' +
    rootStore.permId +
    '&prod=' +
    props.params?.prod +
    '&mCat=' +
    props.params?.mcat +
    '&fullwidth=yes' +
    '&mobile=yes'
  )
})
</script>
