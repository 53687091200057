<template lang="pug">
ProductThirdPartyCoverking(v-if='integration.type === "COVERKING"' :params='integration.params')
ProductThirdPartySaddleman(v-else-if='integration.type === "SADDLEMAN"' :params='integration.params')
ProductThirdPartyNorthwest(v-else-if='integration.type === "NORTHWEST"' :params='integration.params')
iframe(v-else :src='iframeUrl' width='100%' height='900')
</template>

<script setup lang="ts">
const props = defineProps<{
  integration: OrderControlApiConfigIntegration
}>()

const iframeUrl = computed(() => {
  const url = props.integration.type === 'CALTREND' ? 'caltrend' : 'configure-tech'
  return `/third-party/${url}/?params=${JSON.stringify(props.integration)}&disableIntegration=all`
})
</script>
