<template lang="pug">
iframe(:src='getIframeUrl' class='w-full h-[900px]')
</template>

<script setup lang="ts">
const rootStore = useRootStore()
const { isProduction } = useUtils()
const props = defineProps<{
  params: any
}>()

const getIframeUrl = computed(() => {
  return [
    'https://www.saddleman.com/iframeintegration/VehicleSelection.php',
    `?SessionID=${rootStore.permId}`,
    `&fabric=${props.params.fabric}`,
    `&CID=${!isProduction() ? 'rtdev' : 'rtv2ac'}`,
  ].join('')
})
</script>
