<template lang="pug">
iframe(:src='getIframeUrl' class='w-full h-[900px]')
</template>

<script setup lang="ts">
const rootStore = useRootStore()
const { isProduction } = useUtils()
const props = defineProps<{
  params: any
}>()
// 12922 = Dev ID
// 11744 - Prod ID
const getIframeUrl = computed(() => {
  const baseUrl = !isProduction()
    ? 'https://sccnorthwest.com/en/help/scciframe_dev'
    : 'https://sccnorthwest.com/en/help/scciframe'
  return `${baseUrl}?fb=${props.params.fb}&SessionID=${rootStore.permId}&pd=11744`
})
</script>
